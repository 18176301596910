import React from 'react'

const MessageItem = ({message, imSender}) => {
    const {content, sender} = message
    return (
        <div className={'p-2 max-w-xs border font-roboto ' + (imSender ? 'self-end  border-trapchat-orange' : 'self-start  border-trapchat-grey ')}>
            <p className='text-trapchat-grey-light'>{content}</p>
            <p className='text-xs text-right text-trapchat-grey-light'>{sender}</p>
        </div>
    )
}

export default MessageItem
