import React from 'react'

const ScrollBanner = () => {
    return (
        <div className='flex w-banner whitespace-nowrap overflow-x-hidden font-title text-base border border-trapchat-orange m-2 p-4'>
            <div className='relative'>
                <ul className='flex animate-scrolling-banner-1'>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                </ul>
                <ul className='flex absolute top-0 animate-scrolling-banner-2'>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                    <li className='m-2'>Le charisme et la tchatche d'un joueur de blackjack</li>
                    <li className='m-2'>Contacte-moi sur le trapchat</li>
                </ul>
            </div>
        </div>
    )
}

export default ScrollBanner
