import { createContext, useReducer } from 'react'

const initialState = { pseudo: '', room: ''}
const trapchatStore = createContext(initialState)
const { Provider } = trapchatStore

const trapchatReducer = (state, action) => {
    switch (action.type) {
        case 'connect':
            return { ...state, pseudo: action.pseudo }
        case 'joinRoom' : 
            return { ... state, room: action.room}
        case 'disconnect':
            return { pseudo: '', senderId: '' }
        default : 
            return new Error()
    }
}

const TrapchatProvider = ({ children }) => {
    const [state, dispatch] = useReducer(trapchatReducer, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { trapchatStore, TrapchatProvider }