import React from 'react'

const Button = ({ children, onClick, width, className }) => {
    let buttonWitdh = width ? width : 'w-full'

    return (
        <button onClick={onClick} className={`h-input ${buttonWitdh} bg-trapchat-grey px-2 flex flex-row items-center justify-center
            text-trapchat-orange font-roboto focus:outline-none active:bg-trapchat-orange active:text-trapchat-grey cursor-pointer` + className}>
            {children}
        </button>
    )
}

export default Button
