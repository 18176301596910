import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { VscRefresh } from "react-icons/vsc";
import { RiFileCopyLine, RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import Chance from 'chance'

import { trapchatStore } from '../provider/Trapchat'
import TextInput from '../component/TextInput';
import Button from '../component/Button'

const Room = () => {
    const { dispatch } = useContext(trapchatStore)
    const navigate = useNavigate()
    let { id } = useParams();
    const [form, setForm] = useState(id ? false : true)
    const [roomCode, setRoomCode] = useState(id)

    let onChangeForm = () => {
        setForm(!form)
    }

    let getNewRoomCode = () => {
        let chance = new Chance()
        let chanceInt = chance.integer({min: 0}) + ""
        console.log(chanceInt)
        setRoomCode(chanceInt)
    }

    let copyRoomCode = () => {
        const { clipboard } = navigator;
        let url = window.location.origin + `/room/${roomCode}`
        clipboard.writeText(url).then(() =>
            alert('Lien copié !')
        );
    }

    let onChangeRoomCode = (event) => {
        event.preventDefault()
        setRoomCode(event.target.value)
    }

    let joinRoom = () => {
        dispatch({ type: 'joinRoom', room: roomCode })
        navigate('/login')
    }

    useEffect(() => {
        if (form)
            getNewRoomCode()
        else if (!id && !form) {
            setRoomCode('')
        }
    }, [form])

    return (
        <div className="flex h-screen w-screen justify-center items-center bg-black">
            <div className='h-96 w-96 p-4 py-16 flex flex-col justify-around items-center border border-trapchat-orange relative font-roboto'>
                <h1 className='text-trapchat-orange font-title'>
                    {form ? "CREER" : "REJOINS"} LE TRAPCHAT
                </h1>
                {
                    form ?
                        <>
                            <div className='flex flex-row justify-between items-center w-full h-input text-trapchat-orange border border-trapchat-orange px-2'>
                                {roomCode}
                                <div className='flex'>
                                    <VscRefresh className='text-2xl' onClick={getNewRoomCode} />
                                    <RiFileCopyLine className='text-2xl' onClick={copyRoomCode} />
                                </div>
                            </div>
                            <Button onClick={joinRoom}>Entre dans le Trapchat</Button>

                        </>
                        :
                        <>
                            <TextInput placeholder='Code Trapchat' onChange={onChangeRoomCode} value={roomCode} />
                            <Button onClick={joinRoom}>Rejoins le Trapchat</Button>
                        </>
                }
                <div onClick={onChangeForm} className={'flex items-center text-trapchat-orange cursor-pointer absolute bottom-2 ' + (form ? 'right-2' : 'left-2')}>
                    {!form && <RiArrowLeftLine className='text-2xl' />}
                    {!form ? "CREER" : "REJOINS"} LE TRAPCHAT
                    {form && <RiArrowRightLine className='text-2xl' />}
                </div>
            </div>
        </div >
    )
}

export default Room;