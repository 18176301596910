import React from 'react'

const Footer = () => {
    return (
        <div className='flex w-screen flex-row justify-between px-2 m-2 text-sm sm:text-xs font-roboto'>
            <div className='text-left'>
                <a className='hover:underline' href='https://twitter.com/Kuuruje_'>Made by @Kuuruje_</a>
            </div>
            <div className='text-right'>
                Based on the original song "TRAPCHAT" by Alpha Wann feat Veust
            </div>
        </div>
    )
}

export default Footer
