import { useContext, useState, useEffect, useReducer } from 'react'
import { trapchatStore } from '../provider/Trapchat'
import { gunContext } from '../provider/Gun'
import MessageList from '../component/MessageList'
import Header from '../component/Header'
import TextInput from '../component/TextInput'
import Button from '../component/Button'

const messageReducer = (state, message) => { 
    return {messages: [...state.messages, message]} 
}

const Chat = () => {
    const { state } = useContext(trapchatStore)
    const { pseudo, room } = state
    const gun = useContext(gunContext)
    // const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [{messages}, dispatch] = useReducer(messageReducer, {messages: []})

    let sendMessage = () => {
        let index = new Date().toISOString()
        if (newMessage === '') return
        let msgToSend = { content: newMessage, sender: pseudo }
        // dispatch(msgToSend)
        gun.get(room).get('msg').get(index).put(msgToSend)
        setNewMessage('')
    }

    let onChangeNewMessage = (event) => {
        event.preventDefault()
        setNewMessage(event.target.value)
    }

    let onPressEnter = (event) => {
        if (event.key === 'Enter')
            sendMessage()
    }

    useEffect(() => {
        const oldMessage = gun.get(room).get('msg')
        oldMessage.map().once(msg => {
            dispatch(msg)
        })
    }, [])

    return (
        <div className='flex flex-col h-screen w-screen bg-black'>
            <Header user={pseudo} room={room} />
            <div className='flex flex-col border m-2 p-4 max-h-msgs h-full divide-y divide-trapchat-grey-light border-trapchat-orange'>
                <MessageList messages={messages} pseudo={pseudo} />
                <div className='flex h-16 items-center justify-between'>
                    <TextInput value={newMessage} onChange={onChangeNewMessage} onKeyPress={onPressEnter} className='mr-1.5' />
                    <Button onClick={sendMessage} width='w-16' className='ml-1.5'>Send</Button>
                </div>
            </div>
        </div>
    )
}

export default Chat
