import { createRef, useEffect } from 'react'
import MessageItem from './MessageItem'

const MessageList = ({ messages, pseudo }) => {   
    let scrollRef = createRef()
    
    useEffect(() => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    })

    return (
        <div className='flex flex-col h-full max-h-full pb-2 space-y-2 overflow-y-scroll scrollbar-thin '>
            {messages.map((message, index) => (
                <MessageItem message={message} key={index} imSender={pseudo.localeCompare(message.sender) == 0} />
            ))}
            <div ref={scrollRef}/>
        </div>
    )
}

export default MessageList
