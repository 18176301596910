import React from 'react'

const TextInput = ({ value, placeholder, onChange, onKeyPress, className }) => {
    return (
        <input type='text' value={value} onChange={onChange} placeholder={placeholder} onKeyPress={onKeyPress}
            className={"h-input font-roboto w-full px-4 outline-none border border-trapchat-grey text-trapchat-grey-light bg-black " + className}  />
    )
}

export default TextInput
