import React from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { useNavigate } from 'react-router'
import Button from './Button'

const Header = ({ user, room }) => {
    let navigate = useNavigate()

    let goToLogin = () => {
        navigate('/room')
    }

    let copyRoomCode = () => {
        const { clipboard } = navigator;
        let url = window.location.origin + `/room/${room}`
        clipboard.writeText(url).then(() =>
            alert('Lien copié !')
        );
    }

    return (
        <div className='flex flex-row justify-between items-center m-2 p-4 border border-trapchat-orange'>
            <p className='font-title text-trapchat-orange'>TRAPCHAT</p>
            <div className='flex flex-row'>
                {user ?
                    <Button onClick={copyRoomCode}>
                        <AiOutlineUser className='text-trapchat-orange text-2xl' />
                        <p className='text-trapchat-orange px-2'>{user}</p>
                    </Button>
                    :
                    <Button onClick={goToLogin}>Rejoindre</Button>
                }
            </div>
        </div>
    )
}

export default Header
