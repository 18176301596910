import { createContext } from 'react'
import Gun from 'gun'

let endpoint = process.env.REACT_APP_ENDPOINT + '/gun' || "http://localhost:4001/gun"

const gun = Gun({
    peers: [
        endpoint
    ]
})

const gunContext = createContext()
const { Provider } = gunContext

const GunProvider = ({ children }) => {
    return <Provider value={gun} >{children}</Provider>
}

export { gunContext, GunProvider }