import React from 'react'
import { useNavigate } from 'react-router'
import PWAPrompt from 'react-ios-pwa-prompt'
import Button from '../component/Button';
import Footer from '../component/Footer';
import ScrollBanner from '../component/ScrollBanner';

const Home = () => {
    let navigate = useNavigate()

    let goToLogin = () => {
        navigate('/room')
    }

    return (
        <div className='flex flex-col h-screen w-screen bg-black justify-between items-center text-trapchat-orange'>
            <ScrollBanner />
            <div>
                <p className='font-title text-5xl lg:text-9xl '>TRAPCHAT</p>
                <Button className='text-2xl mt-6' onClick={goToLogin}>Rejoindre</Button>
            </div>
            <Footer />
        </div>
    )
}

export default Home
