import React from "react";
import { Route, Routes } from "react-router";
import Chat from "./pages/Chat";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Room from "./pages/Room"

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/trapchat' element={<Chat />} />
      <Route path='/room' element={<Room/>} />
      <Route path='/room/:id' element={<Room/>} />
    </Routes>
  );
}

export default App;
