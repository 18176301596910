import { useState, useContext } from 'react'
import Button from '../component/Button'
import TextInput from '../component/TextInput'
import { useNavigate } from 'react-router-dom';
import { trapchatStore } from '../provider/Trapchat'

const Login = () => {
    const { dispatch } = useContext(trapchatStore)
    const navigate = useNavigate()
    const [pseudo, setPseudo] = useState('')


    let onChangePseudo = (event) => {
        event.preventDefault()
        setPseudo(event.target.value)
    }

    let onClickLogin = () => {
        if (pseudo) {
            dispatch({ type: 'connect', pseudo: pseudo })
            navigate('/trapchat')
        }
    }

    let onPressEnter = (event) => {
        if (event.key === 'Enter')
            onClickLogin()
    }

    return (
        <div className='h-screen w-screen flex justify-center items-center bg-black'>
            <div className='h-96 w-96 p-4 py-16 flex flex-col justify-around items-center border border-trapchat-orange'>
                <h1 className='font-title text-trapchat-orange'>TRAPCHAT</h1>
                <div className='h-24 w-full flex flex-col justify-between'>
                    <TextInput placeholder='Pseudo' onChange={onChangePseudo} onKeyPress={onPressEnter} />
                    <Button onClick={onClickLogin}>Se connecter</Button>
                </div>
            </div>
        </div>
    )
}

export default Login
